<template>
  <div class="options-entity">
    <b-form-select
        :class="setClass"
        v-model="entityId"
        :options="options"
        @change="setValue"
        text-field="Name"
        value-field="Entityid"
    ></b-form-select>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: "OptionsEntity",
  props: ['error'],
  created() {
    this.get()
  },
  computed: {
    setClass() {
      return this.error ? 'is-invalid' : ''
    }
  },
  data: () => ({
    options: [],
    entityId: null
  }),
  methods: {
    ...mapActions('user', ['getEntities']),
    setValue() {
      this.$emit('emmitEntity', this.entityId)
    },

    async get() {
      this.options = await this.getEntities()
    }
  }
}
</script>

<style scoped>

</style>