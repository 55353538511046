<template>
  <div>
    <b-modal
        size="lg"
        ref="modal-new-event"
        id="modal-new-event"
        title="Novo Evento"
    >

      <ButtonEventCenter v-on:emmitEventCenter="setOptionEventCenter" :error="errorEventCenter" />

      <b-form @submit.stop.prevent="onSubmit" class="p-3">

        <b-row>
          <b-col md="12">
            <b-form-group id="form-group-title" label="Nome do evento" label-for="title">
              <b-form-input
                  id="title"
                  name="title"
                  ref="title"
                  v-model="form.title"
                  v-validate="{required: true, min: 5}"
                  :state="validateState('title')"
                  aria-describedby="input-title-feedback"
                  data-vv-as="titulo"
                  placeholder="Digite.."
              ></b-form-input>
              <b-form-invalid-feedback id="input-title-feedback">
                {{ veeErrors.first('title') }}
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="12">
            <b-row>
              <b-col md="7">
                <b-form-group id="fieldset-dateStart"  label="Data inicial" label-for="dateStart">
                  <b-form-input
                      id="dateStart"
                      name="dateStart"
                      ref="dateStart"
                      v-validate="{required: true}"
                      :state="validateState('dateStart')"
                      data-vv-as="data inicial"
                      aria-describedby="input-dateStart-feedback"
                      v-model="form.dateStart"
                      type="date"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-dateStart-feedback">
                    {{ veeErrors.first('dateStart') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group id="fieldset-timeStart" label="Hora inicial" label-for="timeStart">
                  <b-form-input
                      id="timeStart"
                      name="timeStart"
                      ref="timeStart"
                      v-validate="{required: true}"
                      :state="validateState('timeStart')"
                      data-vv-as="hora inicial"
                      aria-describedby="input-timeStart-feedback"
                      v-model="form.timeStart"
                      type="time"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-timeStart-feedback">
                    {{ veeErrors.first('dateEnd') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>


        <b-row>
          <b-col md="12">
            <b-row>
              <b-col md="7">
                <b-form-group id="fieldset-dateEnd"  label="Data final" label-for="dateEnd">
                  <b-form-input
                      id="dateEnd"
                      name="dateEnd"
                      ref="dateEnd"
                      v-validate="{required: true}"
                      :state="validateState('dateEnd')"
                      data-vv-as="data final"
                      aria-describedby="input-dateEnd-feedback"
                      v-model="form.dateEnd"
                      type="date"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-dateEnd-feedback">
                    {{ veeErrors.first('dateEnd') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group id="fieldset-timeEnd" label="Hora final" label-for="timeEnd">
                  <b-form-input
                      id="timeEnd"
                      name="timeEnd"
                      ref="timeEnd"
                      v-validate="{required: true}"
                      :state="validateState('timeEnd')"
                      data-vv-as="hora final"
                      aria-describedby="input-timeEnd-feedback"
                      v-model="form.timeEnd"
                      type="time"
                  ></b-form-input>
                  <b-form-invalid-feedback id="input-timeEnd-feedback">
                    {{ veeErrors.first('timeEnd') }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>


        <b-row class="mb-2">
          <b-col md="12">
            <div class="form-group">
              <label>Insituição organizadora do evento</label>
              <OptionsEntity v-on:emmitEntity="setEntity" :error="errorEntity"></OptionsEntity>
              <p class="text-danger">(<b>Atenção!</b> Após cadastrado o evento não será mais permitido alterar a instituição do mesmo)</p>
            </div>
            <br>
          </b-col>

        </b-row>

      </b-form>


      <template #modal-footer="{ cancel }">

        <b-button size="lg" variant="outline-danger" @click="cancel()">
          Cancelar
        </b-button>
        <!-- Button with custom close trigger value -->
        <b-button size="lg" variant="tema-roxo text-white" @click="onSubmit">
          Salvar
        </b-button>
      </template>

    </b-modal>
  </div>

</template>

<script>

import ButtonEventCenter from "@/components/eventCenter/ButtonEventCenter";
import OptionsEntity from "@/components/entity/OptionsEntity";
import {mapActions} from "vuex";
import setNotification from '@/config/showNotifications'

export default {
  name: "ModalNewEvent",
  data: () => ({
    form: {
      name: null,
      title: null,
      dateStart: null,
      dateEnd: null,
      timeStart: null,
      timeEnd: null,
      eventCenterId: null,
      entityId: null
    },
    errorEntity: false,
    errorEventCenter: false
  }),
  methods: {
    ...mapActions('events', ['setStore']),

    setOptionEventCenter(object) {
      this.form.eventCenterId = object.id
      this.errorEventCenter = false
    },
    setEntity(id) {
      this.form.entityId = id
      this.errorEntity = false
    },
    validateState(ref) {
      if (
          this.veeFields[ref] &&
          (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.veeErrors.has(ref);
      }
      return null;
    },
    onSubmit() {
      this.$validator.validateAll().then(result => {

        var isValid = true

        if (!this.form.entityId) {
          this.errorEntity = true
          isValid = false
        } else {
          this.errorEntity = false
        }

        if (!this.form.eventCenterId) {
          this.errorEventCenter = true
          isValid = false
        } else {
          this.errorEventCenter = false
        }

        if (!result) {
          isValid = false
        }

        if (!isValid) {
          return
        }

        this.setSaveEvent()
      });
    },

    async setSaveEvent() {
      let data = await this.setStore(this.form)
      if (data) {
        this.setClearForm()
        this.$bvModal.hide('modal-new-event')
        this.$emit('emmitNewEvent')
        setNotification(this, 'success', 'Evento criado!!')
      }
    },

    setClearForm() {
      this.form.title         = null
      this.form.dateStart     = null
      this.form.timeStart     = null
      this.form.dateEnd       = null
      this.form.timeEnd       = null
      this.form.entityId      = null
      this.form.eventCenterId = null
      this.errorEntity        = false
      this.errorEventCenter   = false
    }
  },
  components: {
    OptionsEntity,
    ButtonEventCenter
  }
}
</script>

<style scoped>

</style>