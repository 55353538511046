<template>
  <div>
    <!--    <Header></Header>-->
    <SecondaryMenu title="Eventos | Detalhes Evento" />

    <b-col md="12">
      <b-col md="12">
        <b-col md="12">
          <b-container fluid>
            <b-row>
              <b-col md="12">

                <div class="card">
                  <div class="card-body card-body-search-events">
                    <div class="float-left">
                      <h4 class="aes-title-page">
                        <b-button class="btn btn-sm btn-tema-roxo" @click="setNewEvent">+ Novo</b-button>
                      </h4>
                    </div>
                    <div class="float-right">
                      <b-input-group>
                        <b-input
                            class="form-control"
                            placeholder="Buscar..."
                            v-model="search"
                            v-on:keyup.enter="get">
                        </b-input>
                        <b-input-group-append>
                          <b-button variant="outline-secondary" @click="get"><i class="fa fa-search"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </div>

                <b-row class="mt-3">
                  <CardEvent v-for="item in events"
                             :key="item.id"
                             :item=item
                             v-on:emmitReloadEvents="getNewEvent"
                  ></CardEvent>
                </b-row>

                <b-row class="mt-1 p-3" v-if="totalPage > 1">
                  <paginate
                      :pageCount="totalPage"
                      :clickHandler="setPage"
                      container-class="pagination justify-content-center"
                      page-class="page-item"
                      page-link-class="page-link"
                      prev-class="page-item"
                      prev-link-class="page-link"
                      next-class="page-item"
                      next-link-class="page-link"
                      prev-text="<<"
                      next-text=">>"
                  >
                  </paginate>
                </b-row>

              </b-col>

            </b-row>

          </b-container>
        </b-col>
      </b-col>
    </b-col>

    <ModalNewEvent v-on:emmitNewEvent="getNewEvent" />
  </div>
</template>

<script>
import CardEvent from "@/components/events/CardEvent";
import ModalNewEvent from "@/components/events/ModalNewEvent";
import {mapActions} from 'vuex'
import Paginate from 'vuejs-paginate'
import SecondaryMenu from "@/components/layout/SecondaryMenu";

export default {
  name: "ListEvents",
  components: {
    ModalNewEvent,
    CardEvent,
    Paginate,
    SecondaryMenu
  },
  data: () => ({
    events: [],
    totalPage: 0,
    pageCurrent: null,
    search: null
  }),
  created() {
    this.pageCurrent = 1
    this.get()
  },
  methods: {
    ...mapActions('events', ['getMyEvents', 'searchEvents', 'setInscription', 'setInscriptionWorkshop']),

    async get() {

      var data = []
      if (this.search) {
        data = await this.searchEvents({page: this.pageCurrent, search: this.search})
      } else {
        data = await this.getMyEvents({page: this.pageCurrent})
      }

      if (data) {
         this.events = data.data
         this.totalPage = data.lastPage
      }
    },

    async setPage(page) {
      this.pageCurrent = page
      await this.get(page)
    },

    setNewEvent() {
      this.$bvModal.show('modal-new-event')
    },

    getNewEvent() {
      this.get()
    }
  }
}
</script>

<style scoped>
  .card-body-search-events {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .aes-title-page {
    margin-top: 4px;
    font-size: 18px;
    line-height: 26px;
    font-weight: 600;
  }
</style>