
export default function formatDateEvent( dateStart, dateEnd) {
    
    dateStart   = (dateStart.split(' '))[0]
    dateEnd     = (dateEnd.split(' '))[0]

    var exStart = dateStart.split('-')
    var exEnd   = dateEnd.split('-')
    
    var equalYear   = false
    var equalMonth  = false
    //var equalDay    = false
    

    if (dateStart === dateEnd) {
        return exStart[2] + " de "+ getMonth(exStart[1]) + " " + exStart[0];
    } else {
        

        /*if (exStart[0] === exEnd[0]){
            equalDay = true
        }*/
        if (exStart[1] === exEnd[1]){
            equalMonth = true
        }
        if (exStart[0] === exEnd[0]){
            equalYear = true
        }

        if (equalYear === true){
            if (equalMonth === true){
                return exStart[2] + " à " + exEnd[2] + " de "+ getMonth(exStart[1]) + " " + exStart[0];
            } else {
                return exStart[2] + " de "+ getMonth(exStart[1]) + " à " + exEnd[2] + " de "+ getMonth(exEnd[1]) + " " + exEnd[0];
            }

        } else {
            return exStart[2] + " de "+ getMonth(exStart[1]) + " " + exStart[0] + " à " + exEnd[0] + " de "+ getMonth(exEnd[1]) + " " + exEnd[0];
        }
    }
}


function getMonth(month) {
    switch (month){
        case "01": return "Janeiro";
        case "02": return "Fevereiro";
        case "03": return "Março";
        case "04": return "Abril";
        case "05": return "Maio";
        case "06": return "Junho";
        case "07": return "Julho";
        case "08": return "Agosto";
        case "09": return "Setembro";
        case "10": return "Outubro";
        case "11": return "Novembro";
        case "12": return "Dezembro";
    }
}