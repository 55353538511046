<template>
  <div class="col-md-4 box-card mb-3 text-left">
    <div class="card d-block card-hover card-border">
      <img class="card-img-top" :src="img" height="300">
      <div class="card-body">



        <h4 class="card-title d-flex">
          <span class="float-left">
            <i :class="`fa fa-circle circle-${item.status.color} width-circle`"
               v-b-tooltip.hover :title="item.status.event"
            >
          </i>&nbsp;
          {{ item.nome }}
          </span>
        </h4>
        <br>
        <h6>
          <i class="fa fa-calendar-alt ml-1 mr-1"></i> {{ date_format }}
        </h6>
        <h6><i class="fa fa-place-of-worship"></i> {{ item.entidade.Name }}</h6>
        <h6><i class="fa fa-map-marker-alt ml-1 mr-1"></i> {{ item.entidade.Name }}</h6>
      </div>
      <div class="modal-footer">
        <div class="centralize">
          <b-link v-if="item.encerrado_inscricao" class="color-link1" @click="updateInscriptionEvent(false)">Abrir Inscrições</b-link>
          <b-link v-else class="color-link2" @click="updateInscriptionEvent(true)">Fechar Inscrições</b-link>
           |
          <b-link v-if="item.fechado_inscricao_workshop" class="color-link1" @click="updateInscriptionWorkshop(false)"> Abrir Inscrições Workshop</b-link>
          <b-link v-else class="color-link2" @click="updateInscriptionWorkshop(true)"> Fechar Inscrições Workshop</b-link>
           |
          <b-link variant="outline-dark" @click="showDetails"> Editar</b-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import formatDateEvent from '@/config/formatDateEvent'
import {mapActions} from 'vuex'

export default {
  name: "CardEvent",
  props: ['item'],
  data: () => ({
    selected: [], // Must be an array reference!
    options: [
      {text: 'Evento', value: 'evento'},
      {text: 'WorkShop', value: 'workshop'}
    ]
  }),
  computed: {
    img() {
      return 'https://sistemas.usb.org.br/' + (this.item.foto ? this.item.foto : this.item.centro_evento.foto)
    },
    date_format() {
      return formatDateEvent(this.item.inicio, this.item.fim)
    }
  },
  methods: {
    ...mapActions('events', ['setInscription', 'setInscriptionWorkshop']),
    showDetails() {
      this.$router.push('/event-details/' + this.item.id)
    },

    async updateInscriptionEvent(option) {

      let data = await this.setInscription({id:this.item.id, option:option})

      if (data) {
        this.$emit('emmitReloadEvents')
      }
    },

    async updateInscriptionWorkshop(option) {

      let data = await this.setInscriptionWorkshop({id:this.item.id, option:option})

      if (data) {
        this.$emit('emmitReloadEvents')
      }
    }

  }
}
</script>

<style scoped>
  .color-link1 {
    color: #048c45;
}
  .color-link2 {
    color: #a80008;
}
  .centralize {
    margin-right: 20px;
  }
</style>